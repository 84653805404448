<template>
  <div class="contact-form" ref="contactFormElement">
    <div class="content">
      <h2 class="title" ref="titleElement" v-if="data.title">{{ data.title }}</h2>
      <StructuredText
        class="description rich-text"
        ref="descriptionElement"
        v-if="data.description"
        :data="data.description"
        :render-block="renderBlock"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContactFormFragment } from '#gql';
import useTextFadeIn from '~/utils/useTextFadeIn';
import { StructuredText } from 'vue-datocms';
import renderBlock from '~/dato/renderBlock';

const props = defineProps<{
  data: ContactFormFragment;
}>();

const descriptionElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);
const contactFormElement = ref<null | HTMLElement>(null);

useTextFadeIn(contactFormElement, [titleElement, descriptionElement]);
</script>

<style scoped lang="scss">
.contact-form {
  @extend %base-grid;

  padding: var(--base-component-padding-block) var(--base-component-padding-inline);
  background-color: var(--background, var(--color-off-white));

  > .content {
    @media (--vs) {
    }
    @media (--vl) {
      grid-area: 1 / 2 / 2 / 3;
    }

    > .title {
      @include text-md(700);

      padding-bottom: 0.5rem;
    }

    > .description {
      @include text-md(300);
    }
  }
}
</style>
