<template>
  <div class="content-text" ref="contentTextElement">
    <div class="grid">
      <h2 class="title" ref="titleElement">{{ data.title }}</h2>
      <div class="textfields" ref="textfieldsElement" v-if="data.textFields?.length">
        <div class="textfield" v-for="field in data.textFields" :key="field.id">
          <h3 class="title" v-if="field.title">{{ field.title }}</h3>
          <StructuredText
            class="rich-text"
            v-if="field.content"
            :data="field.content"
            :render-block="renderBlock"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentTextFragment } from '#gql';
import useTextFadeIn from '~/utils/useTextFadeIn';
import { StructuredText } from 'vue-datocms';
import renderBlock from '~/dato/renderBlock';

const props = defineProps<{
  data: ContentTextFragment;
}>();

const contentTextElement = ref<null | HTMLElement>(null);
const textfieldsElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);

useTextFadeIn(contentTextElement, [titleElement, textfieldsElement]);
</script>

<style scoped lang="scss">
.content-text {
  background-color: var(--background, var(--color-off-white));

  @media (--vs) {
    padding: 3rem var(--base-component-padding-inline-with-margins);
  }
  @media (--vl) {
    padding: 4rem var(--base-component-padding-inline-with-margins) 6rem
      var(--base-component-padding-inline-with-margins);
  }

  > .grid {
    @extend %base-grid;

    @media (--vl) {
      > .title {
        grid-area: 1 / 1 / 2 / 2;
      }

      > .textfields {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    > .title {
      @include text-2xl(700);

      hyphens: auto;
    }

    > .textfields {
      display: flex;
      flex-direction: column;

      @media (--vs) {
        gap: 1.5rem;
      }
      @media (--vl) {
        gap: 2.5rem;
      }

      > .textfield {
        > .title {
          @include text-md(700);

          padding-bottom: 0.5rem;
        }

        > .text {
          @include text-md(300);
        }
      }
    }
  }
}
</style>
