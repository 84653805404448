<template>
  <div class="content-team grid-accordion" :style="{ '--grid-cols-vl': gridColumns }">
    <h2 class="title">{{ data.title }}</h2>
    <div class="row -top">
      <div class="grid">
        <TeamMember
          :class="{ '-short': gridColumns === 2 }"
          v-for="(m, index) in data.featuredTeamMembers"
          :key="m.id"
          :data="m"
          :style="{ '--transition-delay': `${(index % gridColumns) * 200 + 150}ms` }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TeamMember from '~/components/content-modules/components/TeamMember.vue';
import type { ContentTeamFragment } from '#gql';

const props = defineProps<{
  data: ContentTeamFragment;
}>();

const gridColumns = computed(function () {
  if (props.data.featuredTeamMembers.length === 2 || props.data.featuredTeamMembers.length === 4) {
    return 2;
  }
  return 3;
});
</script>

<style scoped lang="scss">
.content-team {
  padding: var(--base-component-padding-block) var(--base-component-padding-inline-with-margins);
  background-color: var(--background, var(--color-off-white));

  > .title {
    @include text-lg(700);

    padding: 0 0 2rem 0;
  }

  > .team {
    display: grid;

    @media (--vs) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1.5rem;
    }

    @media (--vl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2rem;
    }
  }
}
</style>
