import type { Ref } from 'vue';
import { SplitText } from 'gsap/SplitText';
import gsap from 'gsap';

gsap.registerPlugin(SplitText);

export default function useLinesFadeIn(
  elements: Ref<HTMLElement | null>[],
  options = { delay: 0 }
) {
  onMounted(function () {
    const unwrapped = elements
      .map((i) => i.value)
      .filter((i) => i instanceof HTMLElement) as HTMLElement[];

    unwrapped.forEach((el) => el.classList.add('text-lines-fade-in'));

    const split = new SplitText(unwrapped, {
      type: 'lines,words',
      linesClass: 'line',
      wordsClass: 'word'
    });

    gsap.fromTo(
      split.lines,
      {
        '--y': 200,
        opacity: 0
      },
      {
        '--y': 0,
        opacity: 1,
        stagger: 0.15,
        duration: 1.1,
        delay: 0.25 + options.delay,
        ease: 'power3.out'
        // ease: 'power2.out'
      }
    );
  });
}
