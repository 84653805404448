import type { Ref } from 'vue';
import gsap from 'gsap';
import useIntersectionObserver from '~/utils/useIntersectionObserver';

export default function useTextFadeIn(
  ctEl: Ref<HTMLElement | null>,
  elements: Ref<HTMLElement | null>[]
) {
  const { start, stop, isElementVisible } = useIntersectionObserver(ctEl);

  const tl = gsap.timeline({
    paused: true,
    onComplete: stop
  });

  watch(isElementVisible, function () {
    tl.play(0);
  });

  onMounted(function () {
    addAnimation();
    start();
  });

  function addAnimation() {
    const unwrapped = elements
      .map((i) => i.value)
      .filter((i) => i instanceof HTMLElement) as HTMLElement[];

    tl.fromTo(
      unwrapped,
      {
        y: 60,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        ease: 'power2.out'
      }
    );
  }
}
