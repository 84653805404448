import { h } from 'vue';
// import RichtextSocialRecord from '~/components/richtext/RichtextSocialRecord.vue';

// @ts-ignore
export default function renderBlock({ record, key }) {
  // if (record.__typename === 'RichtextSocialRecord') {
  //   return h(RichtextSocialRecord, { data: record });
  // }
  return null;
}
