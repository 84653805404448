<template>
  <div class="team-member teaser-default">
    <DatoAsset
      :lazy-load="true"
      v-if="data.imageVl"
      :asset="data.imageVl as DatoAssetDto"
      :asset-vs="data.imageVs as DatoAssetDto"
      fade-in="on-intersection"
      class="figure"
    />
    <span class="placeholder" v-else></span>
    <div class="contact-data" :class="{ '-open': isOpen }">
      <div class="inner">
        <button @click="toggle()" class="toggle button-toggle" :class="{ '-open': isOpen }">
          Close
        </button>
        <p class="name">
          <b>{{ data.firstname }} {{ data.lastname }}</b>
        </p>
        <p class="role" v-if="data.role">
          {{ data.role }}
        </p>
        <a
          v-if="data.eMail"
          class="link email"
          target="_blank"
          rel="noopener noreferrer"
          :href="`mailto:${data.eMail}`"
          >{{ data.eMail }}</a
        >
        <a
          v-if="data.phone"
          class="link phone"
          target="_blank"
          rel="noopener noreferrer"
          :href="`tel:${data.phone}`"
          >{{ data.phone }}</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TeamMemberFragment } from '#gql';
import DatoAsset from '~/dato/DatoAsset.vue';
import type { DatoAssetDto } from '~/dato/dato';

const props = defineProps<{
  data: TeamMemberFragment;
}>();

const isOpen = ref<boolean>(false);

function toggle(open = !isOpen.value) {
  isOpen.value = open;
}
</script>

<style scoped lang="scss">
.team-member {
  overflow: clip;
}

.contact-data {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: 5rem;
  //overflow: clip;

  &.-open {
    z-index: 10;

    > .inner {
      //transform: translateY(-2rem);
      transform: translateY(calc(-100% + 5rem));
      padding-top: 2.5rem;

      > .link {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }

  > .inner {
    background-color: var(--color-off-white);
    padding: 1rem 0;
    width: 100%;
    z-index: 10;
    transition:
      transform 400ms,
      padding-top 400ms;
    transition-timing-function: ease-out;

    > .toggle {
      top: 1rem;
      right: 0;
    }

    > .name {
      @include text-md(700);
    }

    > .role {
      @include text-md(300);
    }

    > .link {
      @include text-md(700);

      width: fit-content;
      position: relative;
      text-decoration: none;
      display: block;
      margin-bottom: 0.5rem;
      transform: translate(0, 1rem);
      opacity: 0;
      transition:
        transform 350ms,
        opacity 350ms;

      &.email {
        transition-delay: 150ms;
      }

      &.phone {
        transition-delay: 250ms;
      }

      &.email,
      &.videocall {
        display: inline-block;
        margin-top: 1rem;
      }

      &::before {
        content: '';
        background: currentColor;
        height: 0.05em;
        min-height: 1px;
        bottom: -0.05em;
        left: 0;
        width: 100%;
        position: absolute;
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition-delay: 0.5s;
      }

      &::after {
        content: '';
        background: currentColor;
        height: 0.05em;
        min-height: 1px;
        bottom: -0.05em;
        left: 0;
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition-delay: 0s;
      }

      @media (hover: hover) {
        &:hover,
        &.-hover {
          &::before {
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition-delay: 0s;
          }

          &::after {
            transform: scaleX(1);
            transform-origin: left;
            transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition-delay: 0.2s;
          }
        }
      }

      &:focus-within,
      &.-focus {
        outline: 1px solid var(--color-red);
        outline-offset: 1px;
      }
    }
  }
}
</style>
