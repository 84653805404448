import ContentText from '~/components/content-modules/ContentText.vue';
import ContentGallery from '~/components/content-modules/ContentGallery.vue';
import ContentTeam from '~/components/content-modules/ContentTeam.vue';
import ContactForm from '~/components/content-modules/ContactForm.vue';

export const ContentModules = {
  ContentTextRecord: ContentText,
  ContentGalleryRecord: ContentGallery,
  ContentTeamRecord: ContentTeam,
  ContactFormRecord: ContactForm
};
